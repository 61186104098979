export const contenu_fr = {
  /* Common */
  lang: "fr",
  /* Home */
  home_header: "Faites un voyage, pas comme les autres!",
  site: "Site",
  login: "Se connecter",
  logout: "Se déconnecter",
  signup: "Inscription",
  search: "Recherche",
  xr_experiences: "expériences xr",
  vr_ar_visits: "Visites en VR et AR",
  archeological_sites: "Sites archéologiques",
  platforms_header: "Accessible sur toutes les plateformes",
  platforms_subheader:
    "Découvrez la réalité augmentée/réalité virtuelle sur les appareils iOS, Android et Windows",
  /* Footer */
  about_us: "À PROPOS DE NOUS",
  about_us_text:
    "est une startup pionnière qui transforme le tourisme culturel, l'éducation et les événements avec la réalité virtuelle, la réalité augmentée et l'IA, offrant des expériences immersives qui donnent vie à l'histoire.",
  privacy_policy: "POLITIQUE DE CONFIDENTIALITÉ",
  get_in_touch: "Contactez-nous",
  /* Login */
  login_header: "Connexion",
  email_placeholder: "Email",
  password_placeholder: "Mot de passe",
  login_button: "Se connecter",
  no_account_text: "Vous n'avez pas de compte? ",
  or: "ou",
  login_with: "Se connecter avec",
  /* Signup */
  sign_up_link: "Inscription",
  signup_header: "S'inscrire",
  firstname_placeholder: "Prénom",
  lastname_placeholder: "Nom de famille",
  phone_placeholder: "Numéro de téléphone",
  sign_up_button: "S'inscrire",
  already_have_account: "Vous avez déjà un compte? ",
  /* Site */
  coming_soon: "À venir",
  sites: "Sites",
  add_to_cart: "Ajouter au panier",
  view_in_cart: "Voir dans le panier",
  new: "Nouveau",
  /* Location */
  watch_now: "Visiter",
  then: "Avant",
  now: "Après",
  locations: "Sites",
  packs: "Packs",
  comparison: "Comparaison",
  test_device: "Testez votre appareil",
  currency: "DT",
  hours: "Heures",
  directions: "itinéraires",
  /* Pack */
  description: "Description",
  /* Payment */
  payment_error: "Erreur de paiement",
  payment_error_message:
    "Impossible de traiter le paiement, veuillez réessayer plus tard.",
  return: "Retour",
  /* Cart */
  cart: "Panier",
  your_cart: "Votre panier",
  cart_is_empty:
    "Le panier est vide, parcourez notre collection pour trouver ce dont vous avez besoin.",
  go_back: "Retour",
  total: "Total",
  proceed_to_checkout: "Passer à la caisse",
  /* Visits */
  visits: "Visites",
  your_visits: "Vos visites",
  no_visits:
    "Aucune visite trouvée dans votre compte. Parcourez notre collection pour trouver ce dont vous avez besoin.",
  productAdded: "Produit ajouté à vos visites.",
  share: "Partager",
  copy: "Copier",
  copied: "Copié",
  pass: "Billet",
  /* Coupon */
  redeem_coupon: "Appliquer un coupon",
  coupon_form_header: "Utilisez votre coupon",
  coupon_input: "Coupon",
  coupon_input_description:
    "Entrez le code coupon que vous avez reçu depuis l'application Max it pour bénéficier d'une réduction sur vos paiements.",
  coupon_input_placeholder: "Entrez votre coupon",
  apply_coupon: "Appliquer le coupon",
  login_required:
    "(Pour utiliser un coupon et obtenir une réduction, vous devez être connecté.)",
  success: "succès",
  coupon_applied:
    "Le coupon a été appliqué avec succès. Il sera appliqué à toutes vos achats jusqu'à",
  continue: "Continuer",
  /* 404 */
  page_not_found: "Page non trouvée",
};
