import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Common/Navbar";
import CookieConsentForm from "./components/Common/CookieConsentForm";
import App from "./App";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { loadSites } from "./services/loadData";
import Footer from "./components/FooterComponents/Footer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { fetchUserData, logoutService } from "./services/auth";
import { LanguageContext, LoggedInContext } from "./Context";
import "./routes/main.css";
import "./i18n";

const RootComponent = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(["language"]);
  const [data, setData] = useState([]);
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const data = await fetchUserData();
      if (data.ok) setLoggedIn(true);
      else setLoggedIn(false);
    };

    getUserData();
  }, []);

  const logout = async () => {
    const response = await logoutService();
    if (response.ok) {
      // Function to delete a specific cookie
      const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      };

      // Delete "cart" and "vouchers" cookies
      deleteCookie("cart");
      deleteCookie("vouchers");

      window.location.reload();
    }
  };

  const load = async (lang) => {
    let sites = await loadSites(lang);
    setData(sites);
  };

  useEffect(() => {
    const url = window.location.href; // Full URL
    const segments = url.split("/");
    const language = segments[segments.length - 1];
    if (["en", "fr", "ar"].includes(language)) {
      load(language);
      setCookie("language", language, { path: "/" });
    } else if (cookies["language"]) {
      load(cookies["language"]);
    } else {
      load("en");
    }
  }, [cookies]);

  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <LoggedInContext.Provider value={loggedIn}>
          <LanguageContext.Provider value={t}>
            <Navbar logout={logout} />
            <App
              t={t}
              sites={data.length > 0 ? data : []}
              loggedIn={loggedIn}
            />
          </LanguageContext.Provider>{" "}
        </LoggedInContext.Provider>
        <div className="line" />
        <Footer t={t} data={data} />
        <CookieConsentForm />
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
