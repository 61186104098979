import { makeRequest } from "./jwtHelpers";

const loadSites = async (language) => {
  try {
    const result = await makeRequest("get", `/sites?language=${language}`);
    return result.data;
  } catch (error) {
    console.error("Error loading sites:", error);
    throw error;
  }
};

const loadSiteByLink = async (link, language) => {
  try {
    const result = await makeRequest(
      "get",
      `/sites/${link}?language=${language}`
    );
    return result.data;
  } catch (error) {
    console.error("Error loading site by link:", error);
    throw error;
  }
};

const loadLocations = async (language) => {
  try {
    const result = await makeRequest("get", `/locations?language=${language}`);
    return result.data;
  } catch (error) {
    console.error("Error loading locations:", error);
    throw error;
  }
};

const loadProductsBySiteLink = async (siteLink, language) => {
  try {
    const result = await makeRequest(
      "get",
      `/products/${siteLink}?language=${language}`
    );
    return result.data;
  } catch (error) {
    console.error("Error loading products by site link:", error);
    throw error;
  }
};

const loadLocationProductByLinks = async (siteLink, locationLink, language) => {
  try {
    const result = await makeRequest(
      "get",
      `/products/location/${siteLink}/${locationLink}?language=${language}`
    );
    return result.data;
  } catch (error) {
    console.error("Error loading location product by links:", error);
    throw error;
  }
};

const loadPackProductByLinks = async (siteLink, locationLink, language) => {
  try {
    const result = await makeRequest(
      "get",
      `/products/pack/${siteLink}/${locationLink}?language=${language}`
    );
    return result.data;
  } catch (error) {
    console.error("Error loading pack product by links:", error);
    throw error;
  }
};

const loadProductById = async (id, language) => {
  try {
    const result = await makeRequest(
      "get",
      `/products/${id}?language=${language}`
    );
    return result.data;
  } catch (error) {
    console.error("Error loading product by ID:", error);
    throw error;
  }
};

const loadProductsByIds = async (ids, language) => {
  try {
    const idsString = ids.join(","); // Convert the array to a comma-separated string
    const result = await makeRequest(
      "get",
      `/products/products-by-ids?ids=${idsString}&language=${language}`
    );
    return result.data;
  } catch (error) {
    console.error("Error loading products by IDs:", error);
    throw error;
  }
};

const loadProductsByToken = async (token) => {
  try {
    const result = await makeRequest(
      "get",
      `/voucher/get-token-products/${token}`
    );
    result.data.ok = true;
    return result.data;
  } catch (error) {
    return { ok: false, error: error };
  }
};

const loadProductsByVoucher = async (voucher) => {
  try {
    const result = await makeRequest(
      "get",
      `/voucher/get-voucher-products/${voucher}`
    );
    result.data.ok = true;
    return result.data;
  } catch (error) {
    return { ok: false, error: error };
  }
};

const loadVouchersByUser = async () => {
  try {
    const result = await makeRequest("get", "/voucher/get-user-vouchers");
    return { ok: true, data: result.data };
  } catch (error) {
    return { ok: false, error };
  }
};

export {
  loadSites,
  loadSiteByLink,
  loadLocations,
  loadProductsBySiteLink,
  loadLocationProductByLinks,
  loadPackProductByLinks,
  loadProductById,
  loadProductsByIds,
  loadProductsByToken,
  loadProductsByVoucher,
  loadVouchersByUser,
};
