import { Routes, Route, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useContext, useEffect, useState, lazy, Suspense } from "react";
import ScrollToTop from "./components/Common/ScrollToTop";
import { LoggedInContext } from "./Context";

const Login = lazy(() => import("./routes/Login"));
const Accueil = lazy(() => import("./routes/Accueil"));
const PaymentError = lazy(() => import("./routes/PaymentError"));
const Site = lazy(() => import("./routes/Site"));
const NotFound = lazy(() => import("./routes/NotFound"));
const Location = lazy(() => import("./routes/Location"));
const Pack = lazy(() => import("./routes/Pack"));
const PrivacyPolicy = lazy(() => import("./routes/PrivacyPolicy"));
const Cart = lazy(() => import("./routes/Cart"));
const DeleteUser = lazy(() => import("./routes/DeleteUser"));
const Visits = lazy(() => import("./routes/Visits"));
const RedeemCoupon = lazy(() => import("./routes/RedeemCoupon"));

function App({ sites }) {
  const [cookies] = useCookies(["language"]);
  const [language, setLanguage] = useState("en");
  const loggedIn = useContext(LoggedInContext);

  useEffect(() => {
    if (cookies["language"]) {
      setLanguage(cookies["language"]);
    }
  }, [cookies]);

  return (
    <div>
      <ScrollToTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Navigate to={`/${language}`} replace />} />
          <Route
            path="/login"
            element={<Navigate to={`/login/${language}`} replace />}
          />
          <Route
            path="/cart"
            element={<Navigate to={`/cart/${language}`} replace />}
          />
          <Route
            path="/visits"
            element={<Navigate to={`/visits/${language}`} replace />}
          />
          <Route
            path="/privacy-policy"
            element={<Navigate to={`/privacy-policy/${language}`} replace />}
          />
          <Route
            path="/payment-error"
            element={<Navigate to={`/payment-error/${language}`} replace />}
          />
          <Route
            path="/redeem-coupon"
            element={<Navigate to={`/redeem-coupon/${language}`} replace />}
          />

          <Route path="/:language" element={<Accueil sites={sites} />} />
          <Route
            path="/login/:language"
            element={
              loggedIn ? <Navigate to={`/${language}`} replace /> : <Login />
            }
          />
          <Route path="/cart/:language" element={<Cart />} />
          <Route path="/visits/:language" element={<Visits />} />
          <Route path="/privacy-policy/:language" element={<PrivacyPolicy />} />
          <Route
            path="/site/:site/:language"
            element={<Site sites={sites} />}
          />
          <Route
            path="/site/:site/location/:location/:language"
            element={<Location sites={sites} />}
          />
          <Route path="/site/:site/pack/:pack/:language" element={<Pack />} />
          <Route path="/payment-error/:language" element={<PaymentError />} />
          <Route path="/delete-user/:userID" element={<DeleteUser />} />
          <Route path="/redeem-coupon/:language" element={<RedeemCoupon />} />

          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
