import axios from "axios";

let isRefreshing = false; // Prevent multiple refresh requests

const makeRequest = async (method, url, data = null) => {
  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_PATH}${url}`,
      data,
      withCredentials: true, // Send cookies with the request
    });

    return response; // Ensure data exists before returning
  } catch (error) {
    if (error.response?.status === 401 && !isRefreshing) {
      isRefreshing = true; // Prevent multiple refresh attempts

      try {
        await refreshAccessToken();
        isRefreshing = false; // Reset flag
        return makeRequest(method, url, data); // Retry the request
      } catch (refreshError) {
        isRefreshing = false;
        throw refreshError; // Ensure caller knows authentication has failed
      }
    }

    throw error; // Rethrow error for handling by caller
  }
};

// Function to refresh the access token using the refresh token stored in cookies
const refreshAccessToken = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}/users/refresh`,
      {},
      { withCredentials: true }
    );

    return response?.data?.success || true; // Ensure a response exists
  } catch (error) {
    throw new Error("Failed to refresh access token");
  }
};

export { makeRequest };
