export const contenu_ar = {
  /* Common */
  lang: "ar",
  /* Home */
  home_header: "قم برحلة لا مثيل لها",
  site: "موقع",
  login: "تسجيل الدخول",
  logout: "تسجيل الخروج",
  signup: "التسجيل",
  search: "بحث",
  xr_experiences: "تجارب الواقع الافتراضي",
  vr_ar_visits: "زيارات واقع افتراضي و معزز",
  archeological_sites: "المواقع الأثرية",
  platforms_header: "متاح عبر جميع المنصات",
  platforms_subheader: ": تجربة الواقع المعزز / الواقع الافتراضي على أجهزة",
  /* Footer */
  about_us: "معلومات عنا",
  about_us_text:
    "هي شركة ناشئة رائدة تعمل على تحويل السياحة الثقافية والتعليم والأحداث باستخدام الواقع الافتراضي والواقع المعزز والذكاء الاصطناعي، وتقدم تجارب غامرة تجعل التاريخ ينبض بالحياة.",
  privacy_policy: "سياسة الخصوصية",
  get_in_touch: "تواصل معنا",
  /* Login */
  login_header: "تسجيل الدخول",
  email_placeholder: "البريد الإلكتروني",
  password_placeholder: "كلمة المرور",
  login_button: "تسجيل الدخول",
  no_account_text: "ليس لديك حساب؟ ",
  or: "أو",
  login_with: "تسجيل الدخول باستخدام",
  /* Signup */
  sign_up_link: "التسجيل",
  signup_header: "إنشاء حساب",
  firstname_placeholder: "الاسم الأول",
  lastname_placeholder: "الاسم الأخير",
  phone_placeholder: "رقم الهاتف",
  sign_up_button: "تسجيل",
  already_have_account: "لديك حساب بالفعل؟ ",
  /* Site */
  coming_soon: "قريباً",
  sites: "مواقع",
  add_to_cart: "أضف إلى السلة",
  view_in_cart: "عرض في السلة",
  new: "جديد",
  /* Location */
  watch_now: "قم بالزيارة",
  then: "قبل ",
  now: "بعد",
  locations: "المعالم",
  packs: "الحزم",
  comparison: "مقارنة",
  test_device: "اختبر جهازك",
  currency: "دت",
  hours: "ساعة",
  directions: "الاتجاهات",
  /* Pack */
  description: "الوصف",
  /* Payment */
  payment_error: "خطأ في الدفع",
  payment_error_message: "تعذر معالجة الدفع، يرجى المحاولة مرة أخرى لاحقاً",
  return: "العودة",
  /* Cart */
  cart: "السلة",
  your_cart: "سلتك",
  cart_is_empty: "السلة فارغة, تصفح مجموعتنا للعثور على ما تحتاجه.",
  go_back: "العودة",
  total: "المجموع",
  proceed_to_checkout: "المتابعة إلى الدفع",
  /* Visits */
  visits: "الزيارات",
  your_visits: "زياراتك",
  no_visits:
    "لم يتم العثور على أي زيارات في حسابك. تصفح مجموعتنا للعثور على ما تحتاجه.",
  productAdded: "تمت إضافة المنتج إلى زياراتك.",
  share: "مشاركة",
  copy: "نسخ",
  copied: "تم النسخ",
  pass: "تذكرة",
  /* Coupon */
  redeem_coupon: "تطبيق القسيمة",
  coupon_form_header: "استخدم القسيمة الخاصة بك",
  coupon_input: "قسيمة",
  coupon_input_description:
    "أدخل رمز القسيمة الذي حصلت عليه من تطبيق Max it للحصول على خصم على مدفوعاتك.",
  coupon_input_placeholder: "أدخل القسيمة الخاصة بك",
  apply_coupon: "تطبيق القسيمة",
  login_required:
    "(لاستخدام القسيمة والحصول على الخصم، يجب أن تكون مسجل الدخول.)",
  success: "نجاح",
  coupon_applied: "تم تطبيق الكوبون بنجاح. سيتم تطبيقه على جميع مشترياتك حتى",
  continue: "استمر",
  /* 404 */
  page_not_found: "الصفحة غير موجودة",
};
