export const contenu_en = {
  /* Common */
  lang: "en",
  /* Home */
  home_header: "Take a trip, Unlike any other!",
  site: "Site",
  login: "Login",
  logout: "Logout",
  signup: "Signup",
  search: "Search",
  xr_experiences: "XR experiences",
  vr_ar_visits: "VR and AR visits",
  archeological_sites: "Archeological Sites",
  platforms_header: "Accessible Across All Platforms",
  platforms_subheader:
    "Experience Augmented Reality/Virtual Reality on iOS, Android and Windows devices",
  /* Footer */
  about_us: "ABOUT US",
  about_us_text: `is a pioneering startup transforming cultural tourism, education, and events with virtual reality, augmented reality, and AI, offering immersive experiences that bring history to life.`,
  privacy_policy: "Privacy policy",
  get_in_touch: "GET IN TOUCH",
  /* Login */
  login_header: "Login",
  email_placeholder: "Email",
  password_placeholder: "Password",
  login_button: "Login",
  no_account_text: "Don't have an account? ",
  or: "or",
  login_with: "Login with",
  /* Signup */
  sign_up_link: "Sign up",
  signup_header: "Sign Up",
  firstname_placeholder: "Firstname",
  lastname_placeholder: "Lastname",
  phone_placeholder: "Phone number",
  sign_up_button: "Sign up",
  already_have_account: "Already have an account? ",
  /* Site */
  coming_soon: "Coming soon",
  sites: "Sites",
  add_to_cart: "Add to cart",
  view_in_cart: "View in cart",
  new: "New",
  /* Location */
  watch_now: "Visit",
  then: "Then",
  now: "Now",
  locations: "Locations",
  packs: "Packs",
  comparison: "Comparison",
  test_device: "Test Your Device",
  currency: "DT",
  hours: "Hours",
  directions: "Directions",
  /* Pack */
  description: "Description",
  /* Payment */
  payment_error: "Payment Error",
  payment_error_message:
    "Unable to process the payment, please try again later.",
  return: "Return",
  /* Cart */
  cart: "Cart",
  your_cart: "Your Cart",
  total: "Total",
  cart_is_empty: "Cart is empty, browse our collection to find what you need.",
  go_back: "Go back",
  proceed_to_checkout: "Proceed to checkout",
  /* Visits */
  visits: "Visits",
  your_visits: "Your Visits",
  no_visits:
    "No visits found in your account. Browse our collection to find what you need.",
  productAdded: "Product added to your visits.",
  share: "Share",
  copy: "Copy",
  copied: "Copied",
  pass: "Pass",
  /* Coupon */
  redeem_coupon: "Redeem Coupon",
  coupon_form_header: "Redeem Your Coupon",
  coupon_input: "Coupon",
  coupon_input_description:
    "Enter the coupon code you got from the Max it app for a discount on your payments.",
  coupon_input_placeholder: "Enter your coupon",
  apply_coupon: "Apply Coupon",
  login_required:
    "(To redeem a coupon and get a discount, you need to be logged in.)",
  success: "success",
  coupon_applied:
    "Coupon applied successfully. It will be applied to all your purchases until",
  continue: "Continue",
  /* 404 */
  page_not_found: "Page not found",
};
