import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";
import { useCookies } from "react-cookie";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["language"]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    cookies["language"] ? cookies["language"] : "en"
  );

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    setIsOpen(false);
    i18n.changeLanguage(lang);
    removeCookie("language");
    setCookie("language", lang, { path: "/" });
    // Get the current path and split it into parts
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split("/");

    // Update the language part (assuming it's always the last part of the URL)
    pathParts[pathParts.length - 1] = lang;

    // Construct the new path
    const newPath = pathParts.join("/");

    // Set the new path and reload the page
    window.location.pathname = newPath;
  };

  useEffect(() => {
    const url = window.location.href; // Full URL
    const segments = url.split("/");
    const language = segments[segments.length - 1];

    if (["en", "ar", "fr"].includes(language)) {
      setSelectedLanguage(language);
    } else if (cookies["language"]) {
      i18n.changeLanguage(cookies["language"]);
    }
  }, [cookies, i18n]);

  return (
    <div className="language-selector">
      <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
        <img
          src={
            selectedLanguage === "ar"
              ? "/flags/tn.png"
              : selectedLanguage === "en"
              ? "/flags/en.png"
              : "/flags/fr.png"
          }
          alt={selectedLanguage}
          className="flag-icon"
        />
      </button>
      {isOpen && (
        <div className="_dropdown-menu">
          <div
            className="_dropdown-item"
            onClick={() => handleLanguageChange("en")}
          >
            <img src="/flags/en.png" alt="English" className="flag-icon" />
          </div>
          <div
            className="_dropdown-item"
            onClick={() => handleLanguageChange("fr")}
          >
            <img src="/flags/fr.png" alt="French" className="flag-icon" />
          </div>
          <div
            className="_dropdown-item"
            onClick={() => handleLanguageChange("ar")}
          >
            <img src="/flags/tn.png" alt="Arabic" className="flag-icon" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
