import { makeRequest } from "./jwtHelpers";

// Function to authenticate user and set access token in cookies
const authenticationService = async (userData) => {
  try {
    await makeRequest("post", "/users/authentication", userData);

    return { ok: true };
  } catch (error) {
    return { ok: false };
  }
};

// Function to logout and clear JWT cookies
const logoutService = async () => {
  try {
    await makeRequest("delete", "/users/logout");
    return { ok: true };
  } catch (error) {
    console.error("Error logging out:", error);
    return { ok: false };
  }
};

// Function to fetch user data
const fetchUserData = async () => {
  try {
    const userData = await makeRequest("get", "/users/get-user-data");
    userData.ok = true; // Add a flag to indicate success
    return userData; // Return the user data
  } catch (error) {
    return { ok: false };
  }
};

export { authenticationService, logoutService, fetchUserData };
